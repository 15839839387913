import uajs from './ua';

export default function () {

  // スマホタブレットでなければロールオーバーで使用する（二回タップ対策用）クラスを追加

  const ua = uajs();

  if (ua.Mobile || ua.Tablet) {
  } else {
    const hoverItems = document.querySelectorAll('a');
    const hoverBtns = document.querySelectorAll('button');

    [].forEach.call(hoverItems, el => {
      el.classList.add('is-hover');
    });
    [].forEach.call(hoverBtns, el => {
      el.classList.add('is-hover');
    });
  }

}
